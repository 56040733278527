import React from "react"
import { ServicePageLayout, layoutColorTypes } from "../../../layouts/service-page/service-page.layout"
import { getCanonicalUrl } from "../../../utils/getCanonicalUrl"

import { testimonials } from "./testimonials"
import { getStaticImgUrl } from "../../../utils/getStaticImgUrl"

const content = {
  title: "Content Writing Services",
  whatWeOffer: {
    leftPart: "As a content writing agency, our goal is to provide clients with top-quality content catered to their specific needs. Our specialized in-house team of content writers is standing by.",
    rightPart: "Whatever you need, be it a short article or a full-blown ebook, we can help you. Our rates are flexible, depending on the complexity of the task and the sheer volume of work."
  },
  processText: "We are skilled in tackling any content request, and we never shy away from new challenges. Contact our management team today, and let's discuss your project in detail.",
  testimonials: testimonials
}

const meta = {
  title: "Blog Writing Services",
  description: "If you are looking for a content writing agency to manage your blog section, you have come to the right place. At Podroom Creative, we have a team of skilled, versatile bloggers who are trained to adjust to your specific brand voice."
}

const og = {
  image: getStaticImgUrl("img_contnet_writing_full.jpg")
}

const pageSlug = "content-services/content-writing";

export default () => (

  <ServicePageLayout 

    meta={meta}
    og={og}
    layoutColorType={layoutColorTypes.YELLOW}
    rightHeroImage={"/img_contnet_writing_full.jpg"}
    headerIcon={"/ico_content_writing_2@2x.png"}
    content={content}
    canonicalUrl={getCanonicalUrl(pageSlug)}

  />

)